<template>
    <footer ref="footer" class="footer container">
        <FooterCards v-if="isTruePage" />
        <div class="footer__container">
            <div class="footer__container__upper">
                <div class="footer__container__upper__logo">
                    <router-link :to="{ name: 'home'}">
                        <Icon name="logo" />
                    </router-link>
                    <span class="date">
                        ©{{new Date().getFullYear()}} by Lofty
                    </span>
                </div>
                <div class="footer__socials">
                    <span>{{ $t("footer.our_socials") }}</span>
                    <div class="footer__socials__blocks">
                        <a
                            href="https://www.instagram.com/loftytoday?igsh=MWk1ejMzbG01YmlpaA=="
                            target="_blank"
                            class="footer__socials__block"
                        >
                            <Icon name="instagram2" />
                            <span>Instagram</span>
                        </a>
                        <a
                            href="https://hh.ru/employer/4137277"
                            target="_blank"
                            class="footer__socials__block"
                        >
                            <Icon name="hh2" />
                            <span>HH.ru</span>
                        </a>
                        <a
                            href="https://ru.linkedin.com/company/lofty-today"
                            target="_blank"
                            class="footer__socials__block"
                        >
                            <Icon name="linkedin2" />
                            <span>LinkedIn</span>
                        </a>
                        <a
                            href="https://career.habr.com/companies/loftytoday"
                            target="_blank"
                            class="footer__socials__block"
                        >
                            <Icon name="habr2" />
                            <span>Habr</span>
                        </a>
                    </div>
                </div>
            </div>
            <!-- <div class="footer__container__lower">
                <router-link to="/privacy_policy">{{ $t("footer.policy") }}</router-link>
                <router-link to="/terms_conditions">{{ $t("footer.agreement") }}</router-link>
            </div> -->
        </div>
        <ScrollUp v-if="visibleScroll" :bottom="bottom"></ScrollUp>
    </footer>
</template>

<script setup>
import FooterCards from "@/components/Footer/FooterCards";
import ScrollUp from '@/components/Footer/ScrollUp';
import { onMounted, ref, computed } from 'vue';
import { useRoute } from "vue-router";

const route = useRoute();

const visibleScroll = ref(false);
const bottom = ref(false);
const footer = ref(null);

//eslint-disable-next-line
const scrollTop = () => {
    window.scrollTo(0,0);
};
const handleScroll = () => {
    bottom.value = window.innerHeight >= footer.value.getBoundingClientRect().top;
    visibleScroll.value = window.scrollY > 500
};

onMounted(() => {
    window.addEventListener('scroll', handleScroll)
});

const isTruePage = computed(() => {
    if (route.name == 'privacy' || route.name == 'terms') {
        return false;
    } else {
        return true;
    }
});
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 120px;
    padding: 70px 18px;
    box-sizing: border-box;

    @media (max-width: 1440px) {
        max-width: 1256px;
        padding: 48px 18px 53px 18px;
    }

    @media (max-width: 768px) {
        padding: 48px 18px 53px 18px;
    }

    @media (max-width: 360px) {
        padding: 40px 10px 53px 10px;
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;

        &__upper {
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            padding: 48px 0 31px 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;
            flex-wrap: wrap;

            &__logo {
                display: flex;
                flex-direction: column;
                gap: 26px;

                span {
                    color: $text-main;
                    opacity: 0.2;
                }
                
                .svg-icon {
                    width: 130px;
                    height: 58px;
                }

                @media (max-width: 360px) {
                    gap: 10px;

                    .svg-icon {
                        width: 64px;
                        height: 28px;
                    }

                    span {
                        font-size: 12px;
                    }
                }
            }

            @media (max-width: 360px) {
                flex-direction: column;
                gap: 26px;
            }
        }

        &__lower {
            display: flex;
            justify-content: flex-end;
            gap: 24px;
            
            a {
                text-decoration: underline;
                color: $text-main;
                opacity: 0.5;
                font-size: 12px;
                font-weight: 500;
                line-height: 12px;
                transition: 0.3s;

                &:hover {
                    opacity: 1;
                }
            }

            @media (max-width: 360px) {
                flex-direction: column;
            }
        }
    }

    &__socials {
        display: flex;
        flex-direction: column;
        gap: 12px;

        span {
            color: $text-main;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            opacity: 0.75;

            @media (max-width: 360px) {
                font-size: 12px;
            }
        }

        &__blocks {
            display: flex;
            flex-direction: row;
            gap: 40px;

            @media (max-width: 360px) {
                gap: 0;
                justify-content: space-between;
            }
        }

        &__block {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 8px;
            opacity: 0.5;
            border-radius: 10px;
            transition: 0.3s;
            cursor: pointer;

            span {
                font-size: 12px;
                font-weight: 500;
                line-height: 100%;
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}
</style>