<template>
  <div class="jobs">
    <h2 v-if="jobsPage" class="jobs__title">{{ $t('jobs.title') }}</h2>
    <div class="jobs__filters" :class="{ 'jobs__filters-page': jobsPage}">
      <div class="jobs__filter--wrap">
        <router-link
          :class="{'jobs__filter--active': 'all' === currentFilter }"
          class="jobs__filter"
          :to="{ hash: '#vacancy' }">
          {{$t('join.see_all')}}
        </router-link>
      </div>
      <div class="jobs__filter--wrap" v-for="(item, key) in departments" :key="key">
        <router-link
          :class="{'jobs__filter--active': key === currentFilter }"
          class="jobs__filter"
          :to="{ hash: '#vacancy', query: { filter:  key } }">
          {{item.title}}
        </router-link>
      </div>
    </div>
    <div v-if="isLoading">
      <Loader />
    </div>
    <div class="jobs__items" v-else-if="jobs.length">
      <Job v-for="(job, index) in itemCount" :key="`job_${index}`" :params="job" :filter="currentFilter"></Job>
    </div>
    <div class="jobs__empty" v-else >
        {{$t('jobs.empty')}}
    </div>
  </div>
</template>

<script>
  import { getJobs } from "@/api";
  import Job from "@/components/Jobs/JobItem.vue"
  import Loader from "@/components/Loader.vue"
  import { DEPARTMENTS, DEPARTMENTS_ITEMS } from "@/constants.js"
  import { useI18n } from "vue-i18n";

  export default {
    components: {
      Job,
      Loader
    },
    props: {
      jobsPage: {
        type: Boolean
      }
    },
    data() {
      return {
        isLoading: false,
        jobs: [],
        currentFilter: 'all',
        departments: DEPARTMENTS,
        departmentsItems: DEPARTMENTS_ITEMS
      }
    },
    mounted() {
      if (this.$route.query.filter && this.departments[this.$route.query.filter]) {
        this.currentFilter = this.$route.query.filter;
      }
      this.getJobs();
    },
    computed: {
      itemCount() {
        if (!this.jobsPage) {
          return this.jobs.slice(0, 6);
        } else {
          return this.jobs;
        }
      }
    },
    methods: {
      async getJobs() {

        this.isLoading = true;

        try {
           const response =  await getJobs(this.departmentsItems[this.currentFilter], useI18n().locale.value.toUpperCase())
            this.jobs = response.data.items;
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e)
        } finally {
          this.isLoading = false;
        }

      }
    }
  }
</script>

<style lang="scss">
  .jobs {
    &__title {
      width: 100%;
      margin: 0;
      color: $text-main;
      font-size: 40px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: -0.03em;
      text-align: left;

      @media (max-width: 1440px) {
        display: none;
      }
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 24px;
      
      @media (max-width: 1440px) {
        gap: 20px;
      }

      @media (max-width: 1024px) {
        gap: 12px;
      }
      
      @media (max-width: 650px) {
        flex-direction: column;
      }
     }

    &__filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      gap: 8px;

      @media (max-width: 1440px) {
        justify-content: center !important;
      }

      @media (max-width: 360px) {
        max-width: calc(100vw - 36px);
        overflow-x: scroll;
        flex-wrap: nowrap;
        justify-content: flex-start !important;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-page {
        justify-content: flex-start;
      }
    }

    &__filter {
      border: 1px solid $dark;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 5px 10px;
      cursor: pointer;

      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      white-space: nowrap;
      transition: 0.3s;

      color: $dark;
      cursor: pointer;

      &--wrap {
        display: flex;
        align-items: center;
      }

      &--active {
        background: $white!important;
        border: 1px solid $white;
        cursor: default;
        color: $dark!important;
      }
    }

      &__empty {
          border-bottom: 1px solid rgba(46, 57, 72, 0.08);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 50px 0;
          font-size: 18px;
      }
  }

  .no-touch {
    .jobs__filter {

      &:hover {
        background: $dark;
        color: $white;
      }
    }

    .jobs__filters {
      @media (max-width: $tablet-point) {
        flex-wrap: wrap;
      }
    }

  }
</style>
